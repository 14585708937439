import React from 'react'
import Layout from "../components/layout";
import Seo from "../components/seo";
import Markdown from 'markdown-to-jsx';
import BaseContainer from "../components/common/BaseContainer";


const md = `

# Polityka Prywatności
Opisane w niniejszej polityce prywatności zasady przetwarzania, powierzania i ochrony danych osobowych stosuje się zarówno w odniesieniu do danych zgromadzonych w trakcie użytkowania Serwisu, w tym formularza kontaktowego, oraz w związku z wykonywaniem umów zawartych przez Administratora z użytkownikami, jak również pozostałych form komunikacji z Administratorem.
## Podstawa prawna
Niniejsza Polityka Prywatności jest zgodna z przepisami wynikającymi z  art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w skrócie RODO.
## Serwis
Serwis internetowy MUSIC POINT, działający pod adresem internetowym  [musicpoint.pl](https://www.musicpoint.pl/)
(dalej jako „Serwis”).
## Administrator danych osobowych
Właścicielem, operatorem Serwisu oraz administratorem przetwarzanych danych osobowych jest Music Point Sp. z o.o. z siedzibą w Warszawie przy ul. Lipskiej 5, wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla M.St. Warszawy w Warszawie Wydział XIII Gospodarczy Krajowego Rejestru Sądowego pod nr KRS 0000467994, posiadająca NIP 1132863106 oraz nr REGON 14655864800000, kapitał zakładowy: 378.000 zł; (dalej jako „Administrator”). Administrator zastrzega sobie możliwość wprowadzania zmian w niniejszej polityce prywatności.

##Użytkownik
Użytkownik to osoba fizyczna korzystająca z Serwisu.
## Postanowienia Ogólne

1. Ochrona danych odbywa się zgodnie z wymogami powszechnie obowiązujących przepisów prawa, a ich przechowywanie ma miejsce na zabezpieczonych serwerach.
2. Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych, dokładając szczególnej staranności w celu ochrony interesów użytkowników, których dane dotyczą. W tym celu podjęte zostały działania zabezpieczające zarówno Serwis – jak i nośniki danych m.in. komputery.
3. Dane osobowe podawane w formularzu kontaktowym dostępnym w Serwisie są traktowane jako poufne i nie są widoczne dla osób nieuprawnionych.

## Dane osobowe i ich wykorzystanie
1. Music Point Sp. z o.o. jest administratorem danych swoich klientów.

2. Dane osobowe przetwarzane są:
  * zgodnie z przepisami dotyczącymi ochrony danych osobowych, w szczególności na podstawie Ustawy z dnia 10 maja 2018 r. o ochronie danych osobowych oraz Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).
  * zgodnie z wdrożoną Polityką Prywatności,
  * w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji Usług świadczonych drogą elektroniczną,
  * w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą

3. Każda osoba, której dane dotyczą (jeżeli Music Point Sp. z o.o. jest ich administratorem) ma prawo:
	* dostępu do danych osobowych,
	* do sprostowania danych osobowych,
	* do usunięcia danych osobowych,
	* do ograniczenia przetwarzania danych osobowych,
	* do wniesienia sprzeciwu co do przetwarzania danych osobowych,
	* do przenoszenia danych,
	* do wniesienia skargi do organu nadzorczego,
	* do odwołania zgody na przetwarzanie danych osobowych, jeżeli takowa zgoda została wcześniej wyrażona.

4.  Kontakt z osobą nadzorującą przetwarzanie danych osobowych w Music Point Sp. z o.o. jest możliwy drogą elektroniczną pod adresem e-mail:  [iodo@musicpoint.pl](mailto:iodo@musicpoint.pl)

5.  Music Point Sp. z o.o. zastrzega sobie prawo do przetwarzania danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują do retencji danych.

6.  Music Point Sp. z o.o. ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania).

7.  Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.

8.  Music Point Sp. z o.o. nie udostępnia danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.

9. Podczas wizyty w Serwisie automatycznie zbierane mogą być dane użytkowników Serwisu, dotyczące danej wizyty użytkownika w Serwisie i obejmujące m. in. adres IP, rodzaj przeglądarki internetowej, nazwę domeny, liczbę odsłon, rodzaj systemu operacyjnego, wizyty, rozdzielczość ekranu, liczbę kolorów ekranu, adresy stron internetowych, z których nastąpiło wejście do Serwisu, czas korzystania z Serwisu.

10. W ramach formularzy kontaktowych dostępnych w Serwisie mogą być zbierane następujące dane osobowe użytkownika: imię i nazwisko, adres poczty elektronicznej, numer telefonu kontaktowego.

## Cookies
Mechanizm funkcjonowania Serwisu w celu identyfikacji użytkowników wykorzystuje pliki typu cookie (informacje zapisywane przez serwer na komputerze użytkownika, które serwer może odczytać przy każdorazowym połączeniu się z danego komputera). Użytkownik może w każdej chwili wyłączyć w swojej przeglądarce opcję przyjmowania cookies, co może jednak uniemożliwić korzystanie z Serwisu.
## Logi serwera
Serwer Serwisu zbiera informacje dotyczące wykorzystania Serwisu przez użytkowników oraz ich adresów IP. Informacje te wykorzystywane są tylko w celach technicznych oraz do tworzenia wszelkiego rodzaju statystyk.
## Przechowywanie i ochrona informacji
Dane osobowe podawane przez użytkowników Serwisu są przechowywane i przetwarzane w sposób zgodny z zakresem udzielonego przez użytkownika zezwolenia oraz wymogami prawa polskiego.
Zbiór zgromadzonych danych osobowych traktowany jest jako wydzielona baza danych, przechowywana na chronionym i certyfikowanym serwerze w sposób zapewniający właściwą ochronę. Pełen dostęp do bazy danych posiada jedynie niewielka liczba uprawnionych osób zajmująca się jej administracją. Hasła użytkowników są przechowywane w postaci zaszyfrowanej i obsługa Serwisu nie ma do nich żadnego dostępu.
## Postanowienia końcowe
Aktualna wersja Polityki Prywatności dostępna jest w Serwisie.
Korzystając z Serwisu użytkownik akceptuje aktualną wersją Polityki Prywatności.
Music Point Sp. z o.o., ul. Lipska 5, 03-904 Warszawa.
`;

const PrivacyPolicy = () => (
  <Layout>
    <Seo title='Polityka prywatności' />
    <main>
    
    <BaseContainer>      
      <Markdown 
        children={md} 
        options={{ 
          forceBlock: true,
          overrides: {
              h1: {                
                props: {
                  style: {'margin-top': '50px'}
                }
              },
              ol: {                
                props: {
                  style: {'list-style': 'auto'}
                }
              },
              li: {                
                props: {
                  style: {'margin-left': '28px', 'font-size': '22px'}
                }
              },
              ul: {                
                props: {
                  style: {'list-style-type': 'circle'}
                }
              }
          }        
        }}
        

        />
    </BaseContainer>
    </main>
  </Layout>
)

export default PrivacyPolicy;
